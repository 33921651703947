import {
  colors,
  gutter,
  media,
  radius,
  spacing,
  Typography,
  typography,
} from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

import { Props } from '.';

export const CategoriesList = styled.div`
  padding: ${spacing.medium} 0 0 0;
  display: flex;
  flex-wrap: wrap;
`;

export const CategoryThumbnail = styled.div`
  margin-bottom: -${spacing.medium};
`;

export const Button = styled.a<Pick<Props, 'color'>>`
  ${({ color }) => css`
    display: flex;
    flex-grow: 1;
    flex-basis: calc(50% - ${gutter.default});
    align-items: center;
    background: ${colors[color]};
    border-radius: ${spacing.small};
    height: ${spacing.large};
    padding: 0 ${spacing.small};
    position: relative;
    text-align: left;
    text-decoration: none;
    margin-bottom: ${spacing.small};

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }

    &:nth-child(odd) {
      margin-right: ${spacing.small};
    }

    &:last-child {
      margin-right: 0;
    }

    p {
      font-weight: 600;
      line-height: 1.2;
    }

    ${media.greaterThan('desktop')`
      border-radius: ${radius.medium};
      height: 100%;
      padding: ${spacing.small} ${spacing.medium};
      margin-bottom: ${spacing.medium};

      &:nth-child(odd) {
        margin-right: ${spacing.medium};
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        height: ${spacing.large};
      }

      > div span {
        align-items: center;
        font-size: ${typography.heading_4};
      }
    `}
  `}
`;

export const Icon = styled.div`
  svg {
    color: ${colors.light};
    height: 1.8rem;
    width: 1.8rem;
    margin-right: ${spacing.small};

    ${media.greaterThan('desktop')`
      height: 4rem;
      width: 4rem;
    `}
  }
`;

export const Tag = styled(Typography)`
  position: absolute;
  right: 0;
  font-size: 1rem;
  background-color: #fff;
  padding: 0.4rem;
  border-radius: ${radius.medium};
  color: ${colors.primary};
  margin-right: ${spacing.medium};
`;
