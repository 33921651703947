import { colors, gap, gutter, spacing } from '@lamimed/pantheon';
import styled, { css } from 'styled-components';

interface WrapperProps {
  shouldReverse: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ shouldReverse }) => css`
    display: flex;
    flex-direction: ${shouldReverse ? 'column-reverse' : 'column'};
    text-align: center;
  `}
`;

export const Thumbnail = styled.div`
  position: relative;
  margin: 0 0 ${spacing.medium} 0;
  text-align: center;

  &:after {
    background: ${colors.gradient_border_color};
    bottom: 2rem;
    content: '';
    display: block;
    height: 20rem;
    left: 50%;
    max-width: 102.4rem;
    opacity: 0.15;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%) rotate(-180deg);
    width: 100vw;
    z-index: -1; // TODO: add Pantheon's token
  }
`;

interface ContentProps {
  gapless?: boolean;
}

export const Content = styled.div<ContentProps>`
  ${({ gapless = false }) => css`
    display: flex;
    flex-direction: column;
    ${!gapless && `margin-bottom: ${spacing.medium};`};

    ${gap(gutter.small)}
  `}
`;
