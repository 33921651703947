import React from 'react';
import {
  Blood,
  Chat,
  Doctor,
  Face,
  House,
  Nurse,
  Typography,
  Virus,
  Xray,
} from '@lamimed/pantheon';
import { useRouter } from 'next/router';

import { Category } from 'models/Category';

import { useSWRGet } from 'hooks/useSWRGet';

import { sendToErrorTracking } from 'utils/sendToErrorTracking';

import ThumbnailDivider from 'components/ThumbnailDivider';

import * as S from './styles';

const icons = {
  'bambini': Face,
  'chat': Chat,
  'covid': Virus,
  'diagnostica-immagini': Xray,
  'esami-test': Blood,
  'infermieri': Nurse,
  'medici': Doctor,
  'telemedicina': House,
};

type Icon = keyof typeof icons;

export interface Props {
  color: 'gradient__primary' | 'gradient__secondary';
  title: string;
  description?: string;
  image: string;
}

const OurServices: React.FC<Props> = ({ color, title, description, image }) => {
  const router = useRouter();

  const { data, error } = useSWRGet<Category[]>(`/top-categories`);

  if (error) {
    sendToErrorTracking({ error, where: 'OurServices', context: data });

    router.push('/500');
  }

  return (
    <>
      <ThumbnailDivider
        title={title}
        description={description}
        image={image}
        gapless
      />

      <S.CategoriesList>
        {data?.map(({ id, title, slug }) => (
          <S.Button key={id} color={color} href={`/${slug}`}>
            {Object.keys(icons).includes(slug) && (
              <S.Icon>{React.createElement(icons[slug as Icon])}</S.Icon>
            )}

            <Typography color="text__light">{title}</Typography>

            {slug === 'chat' && <S.Tag>Gratis</S.Tag>}
          </S.Button>
        ))}
      </S.CategoriesList>
    </>
  );
};

export default OurServices;
