import React, { useEffect } from 'react';
import { Grid, Typography, useViewport } from '@lamimed/pantheon';
import Head from 'next/head';

import { useIsWidget } from 'hooks/useIsWidget';

import { ErrorRedirect, errorRedirect } from 'utils/errorRedirect';

import * as S from 'templates/ErrorPage';

import { footerImageOffset } from 'components/Footer/styles';
import { Header } from 'components/Header';
import OurServices from 'components/OurServices';
import { SectionWrapper } from 'components/wrappers/SectionWrapper';

export const NotFoundTemplate = () => {
  const { greaterThan } = useViewport();
  const { isWidget } = useIsWidget();

  useEffect(() => {
    if (isWidget) errorRedirect(ErrorRedirect.notFound);
  }, [isWidget]);

  return (
    <>
      <Head>
        <title key="title">Pagina non trovata - Lami: Health delivery</title>
      </Head>

      <Header />

      <Grid.Wrapper>
        <S.Hero>
          <S.HeroImageContainer>
            <S.CatImage
              aria-hidden
              src="/images/illustration/cat.gif"
              width={95}
              height={93}
            />

            <S.ErrorImage
              src="/images/illustration/404.png"
              alt="404 - Not found"
              width={495}
              height={295}
              quality={100}
            />
          </S.HeroImageContainer>

          <S.TextWrapper>
            <Typography variant="heading_1" color="text__highlight" as="h1">
              Ops... pagina non trovata.
            </Typography>

            <Typography as="h2">
              La pagina che stavi cercando potrebbe non esistere più, oppure
              l&apos;indirizzo inserito potrebbe essere scorretto o essere stato
              modificato.
              <Typography marginTop="medium">
                Se hai bisogno di aiuto, contattaci via chat, oppure accedi
                direttamente ad uno dei nostri servizi:
              </Typography>
            </Typography>
          </S.TextWrapper>
        </S.Hero>
      </Grid.Wrapper>

      <SectionWrapper
        paddingTop="none"
        paddingBottom={greaterThan('tablet') ? 'xxlarge' : 'xlarge'}
        additionalPadding={footerImageOffset}
      >
        <Grid.Wrapper>
          <OurServices
            title="I nostri servizi"
            description="Scegli uno dei nostri servizi online o a domicilio e prenota in un click. Ti aiuteremo ovunque tu sia."
            image="/images/illustration/categories/homecare.png"
            color="gradient__secondary"
          />
        </Grid.Wrapper>
      </SectionWrapper>
    </>
  );
};
