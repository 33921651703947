import { Typography, useViewport } from '@lamimed/pantheon';
import Image from 'next/legacy/image';

import * as S from './styles';

interface ThumbnailDividerProps {
  image?: string;
  title: string;
  titleLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  description?: string;
  shouldReverse?: boolean;
  gapless?: boolean;
}

const ThumbnailDivider: React.FC<ThumbnailDividerProps> = ({
  image,
  title,
  description,
  shouldReverse = false,
  titleLevel = 'h2',
  gapless,
}) => {
  const { lessThan } = useViewport();

  return (
    <S.Wrapper shouldReverse={shouldReverse}>
      {!!image && (
        <S.Thumbnail>
          <Image
            src={image}
            alt={title}
            width={lessThan('tablet') ? 250 : 263}
            height={lessThan('tablet') ? 180 : 178}
            aria-hidden="true"
            quality={100}
            objectFit="contain"
          />
        </S.Thumbnail>
      )}

      <S.Content gapless={gapless}>
        <Typography
          variant="heading_1"
          color="text__highlight"
          as={titleLevel}
          textAlign="center"
        >
          {title}
        </Typography>

        {description && (
          <Typography variant="body_text_1" textAlign="center">
            {description}
          </Typography>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default ThumbnailDivider;
